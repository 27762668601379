import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { FiChevronRight } from "react-icons/fi";


/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const HeroImage = () => (
    <StaticQuery
        query={graphql`
        query LatestPortfolioItemsQuery {
            allPortfolioItemsJson(sort:{fields:[completedDate], order: DESC}, limit: 1) {
                edges {
                    node {  
                        projectName,
                        publicUrl,
                        clientColor,
                        screenShot {
                            childImageSharp {
                                fluid(maxWidth: 600) {
                                ...GatsbyImageSharpFluid_tracedSVG
                                }
                            }
                        }
                    }
                }
            }
        }
        `}
        render={data => data.allPortfolioItemsJson.edges.map((edge, index) => {
            return (
                <div id="hero-image" className="mx-auto" key={index}>
                    <a target="_blank" rel="noopener noreferrer" href={edge.node.publicUrl}>
                        <Img fluid={edge.node.screenShot.childImageSharp.fluid} className="blob" />
                    </a>
                    <p className="text-center">
                        <small className="text-muted">Latest project: <a className="highlight" target="_blank" rel="noopener noreferrer" href={edge.node.publicUrl}>{edge.node.projectName}<FiChevronRight /></a></small>
                    </p>
                </div>
            );
        })}
    />


    
    )
    export default HeroImage
    
    {/* <StaticQuery
                            key={index+"-imageQuery"}
                                query={graphql`
                query {
                    placeholderImage: file(relativePath: { eq: "koin-hero.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 600) {
                        ...GatsbyImageSharpFluid_tracedSVG
                        }
                    }
                    }
                }
                `}
                                render={data => <Img fluid={data.placeholderImage.childImageSharp.fluid} />}
                            /> */}