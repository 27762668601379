import React from "react";
import { Link } from "gatsby";
import ReactWOW from "react-wow";
import useWindowDimensions from "../components/functions";

import Layout from "../components/layout";
import HeroImage from "../components/heroImage";
import SEO from "../components/seo";
import WorkListLogos from "../components/workListLogos";
import SubHeader from "../components/subheader";

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    // window.addEventListener("resize", this.updateWindowDimensions);
  }

  // componentWillUnmount() {
  //   window.removeEventListener("resize", this.updateWindowDimensions);
  // }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    let { width } = this.state;
    const noCols = width >= 768 ? (width >= 992 ? 3 : 2) : 1;
    const animationDelays = ["0s", "0.3s", "0.6s"];

    return (
      <Layout>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
        <div className="container">
          <div className="row" id="hero">
            <div className="col-md-6 text-center text-md-left">
              <h1>Panda Bridge</h1>
              <h2 className="pb-3">
                Creative Web &amp; Mobile Development Agency
              </h2>
              <p>
                Hi, my name is Chris, I'm a{" "}
                <span className="highlight">web development</span> and{" "}
                <span className="highlight">mobile app development</span>{" "}
                freelancer based in London, UK. I work with organisations, SME's
                and individuals to clarify, plan and execute design and
                technical strategies to realise the full potential of their
                concepts through digital innovation.
              </p>
              <p>
              <Link className="btn btn-primary" to="/contact">Start a Project</Link>
              </p>
            </div>
            <div className="col-md-6 pl-md-5">
              <HeroImage />
            </div>
          </div>
        </div>

        <SubHeader
          title="Services"
          description="I've had the pleasure of working with some truly outstanding
        businesses and entrepreneurs over the years."
          link="portfolio"
        />
        <div className="container">
          <div className="col-md-12">
            <div className="card-deck">
              <ReactWOW
                animation="fadeInUp"
                delay={animationDelays[0 % noCols]}
              >
                <div className="card mb-4">
                  <h4 className="card-title">
                    Digital
                    <br />
                    Strategy
                  </h4>
                  <div className="card-body">
                    <p className="card-text">
                      Collaboratively brainstorm what’s important to you, your
                      passions and what you want to achieve. I believe in the
                      power of face-to-face communication and strongly suggest
                      several on-site meetings with key stakeholders to fully
                      iron out the details.
                    </p>
                  </div>
                </div>
              </ReactWOW>
              <ReactWOW
                animation="fadeInUp"
                delay={animationDelays[1 % noCols]}
              >
                <div className="card mb-4">
                  <h4 className="card-title">
                    Website
                    <br />
                    Development
                  </h4>
                  <div className="card-body">
                    <p className="card-text">
                      I custom develop websites, allowing them to remain unique,
                      scalable and functional. Your website will perform better,
                      be more efficient, and overall it will provide a better
                      experience for you and your users. Whatever your idea, I
                      can turn it in to a reality.
                    </p>
                  </div>
                </div>
              </ReactWOW>

              <ReactWOW
                animation="fadeInUp"
                delay={animationDelays[2 % noCols]}
              >
                <div className="card mb-4">
                  <h4 className="card-title">
                    Mobile
                    <br />
                    Development
                  </h4>
                  <div className="card-body">
                    <p className="card-text">
                      I develop native mobile application across iOS, Android
                      and Windows Mobile, while providing hosted development
                      builds of the mobile application for full visibility of
                      progress, quick agile iterative changes and usability
                      testing.
                    </p>
                  </div>
                </div>
              </ReactWOW>

              <ReactWOW
                animation="fadeInUp"
                delay={animationDelays[3 % noCols]}
              >
                <div className="card mb-4">
                  <h4 className="card-title">
                    eCommerce
                    <br />
                    &nbsp;
                  </h4>
                  <div className="card-body">
                    <p className="card-text">
                      With years of experience in eCommerce, I can develop an
                      eCommerce solution tailored to the needs of yourself, your
                      business, and your customers. I develop effective,
                      efficient and well-designed eCommerce solutions to match
                      your exact requirements; whether it's B2C, B2B, or both.
                    </p>
                  </div>
                </div>
              </ReactWOW>

              <ReactWOW
                animation="fadeInUp"
                delay={animationDelays[4 % noCols]}
              >
                <div className="card mb-4">
                  <h4 className="card-title">
                    Search Engine
                    <br />
                    Optimisation (SEO)
                  </h4>
                  <div className="card-body">
                    <p className="card-text">
                      When creating websites, I keep search engine optimisation
                      (SEO) in mind. I carry out standard on-page SEO practices
                      on every page of every website I create, allowing your
                      site to rank higher in search engine results.
                    </p>
                  </div>
                </div>
              </ReactWOW>

              <ReactWOW
                animation="fadeInUp"
                delay={animationDelays[5 % noCols]}
              >
                <div className="card mb-4">
                  <h4 className="card-title">Maintenance &amp; Management</h4>
                  <div className="card-body">
                    <p className="card-text">
                      We live in a fast-evolving world. Today, online presence
                      is no longer just a set-and-forget digital gadget. You
                      need to take care of often updating your website (not only
                      about the content but also technologies behind it). Quick
                      example – just look how often your desktop or smartphone
                      software requires updating. The same thing is with your
                      website.
                    </p>
                  </div>
                </div>
              </ReactWOW>
            </div>
          </div>
        </div>

        <SubHeader
          title="Recent Clients"
          description="I've had the pleasure of working with some truly outstanding
        businesses and entrepreneurs over the years."
          link="portfolio"
        />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <WorkListLogos />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default IndexPage;
