import React from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import Img from 'gatsby-image'

const WorkListLogos = (props) => (
    <div {...props}>
        <StaticQuery
            query={graphql`
                query {
                    allWorksYaml(limit: 4) {
                        nodes {
                            brandLogo {
                                id
                                childImageSharp {
                                    fluid(grayscale: true, maxHeight: 200) {
                                        ...GatsbyImageSharpFluid
                                        originalImg
                                    }
                                }
                            }
                        }
                    }
                }
            `}
            render={data => (
                <div className="workListLogos">
                    {data.allWorksYaml.nodes.map((node, index) => (
                        <div className="cell" key={index}>
                            <Link to="portfolio">
                                <Img fluid={node.brandLogo.childImageSharp.fluid} />
                            </Link>
                        </div>
                    ))}
                </div>
            )}
        />
    </div>
);

export default WorkListLogos
